import { useId } from 'react';
import { FormControl, InputLabel, MenuItem, Select as MuiSelect, OutlinedInput, SelectChangeEvent } from '@mui/material';
import { SelectProps } from '@/components/common/Select/types';

function Select<TOption, TValue>({
  options,
  value,
  displayFunction,
  valueFunction,
  onChange,
  label,
  placeHolder,
  required,
  fullWidth,
  hasEmptyOption,
  name,
}: SelectProps<TOption, TValue>) {
  const id = useId();

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id={`label-${id}`}>
        {label} {required ? ' *' : null}
      </InputLabel>
      <MuiSelect
        labelId={`label-${id}`}
        name={name}
        data-testid={name ? `select-${name}` : undefined}
        input={<OutlinedInput label={label} />}
        value={value ? value : ''}
        onChange={(event: SelectChangeEvent<TValue>) => onChange(event.target.value as TValue, event.target.name)}
        required={required}
        sx={{ '& .MuiSelect-select': { textOverflow: 'ellipsis' } }}
      >
        {hasEmptyOption ? (
          <MenuItem value="">
            <em>{placeHolder ? placeHolder : 'None'}</em>
          </MenuItem>
        ) : null}
        {options.map((option, index) => (
          <MenuItem key={index} value={(valueFunction ? valueFunction(option) : option) as unknown as string}>
            {displayFunction ? displayFunction(option) : (option as string)}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}

export default Select;
