/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
import type { BerthDmaCaseSafetyResponse } from '../models/BerthDmaCaseSafetyResponse';
import type { BerthRequest } from '../models/BerthRequest';
import type { BerthResponse } from '../models/BerthResponse';
import type { BerthsResponse } from '../models/BerthsResponse';
import type { MooringDetectionResponse } from '../models/MooringDetectionResponse';

export class BerthManagementService {
  /**
   * Download berths CSV
   * Download the berths assigned to a certain organisation in CSV format.
   * @param organisationId
   * @returns any OK
   * @throws ApiError
   */
  public static downloadBerths(organisationId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/berths/file',
      query: {
        organisationId: organisationId,
      },
    });
  }

  /**
   * Upload berths CSV
   * Register multiple berths and assign them to an organisation.
   * @param organisationId
   * @param formData
   * @returns string OK
   * @throws ApiError
   */
  public static registerBerths(
    organisationId: string,
    formData?: {
      file: Blob;
    }
  ): CancelablePromise<Array<string>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/berths/file',
      query: {
        organisationId: organisationId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * Register berths (bulk).
   * Register multiple berths, mostly used when setting up the environment.
   * @param requestBody The berths to register. This endpoint is used for instance for new customers to register the berths needed for their environment.
   * @returns BerthResponse OK
   * @throws ApiError
   */
  public static bulkRegisterBerths(requestBody: Array<BerthRequest>): CancelablePromise<Array<BerthResponse>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/berths/bulk',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Enable mooring detection (bulk)
   * Enable mooring detection for berths. Which means sending a request to the AIS berth service to enable the monitoring of the polygons.
   * @param requestBody The berths to register for mooring detection, returns the berth identifiers for successful registrations.
   * @returns MooringDetectionResponse OK
   * @throws ApiError
   */
  public static enableMooringDetection(requestBody: Array<string>): CancelablePromise<Array<MooringDetectionResponse>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/berths/mooring-detection',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Disable mooring detection (bulk)
   * Disable mooring detection for berths. Which means sending a request to the AIS berth service to disable the monitoring of the polygons.
   * @param requestBody The berths to deregister mooring detection for, returns the berth identifiers for successful deregistrations.
   * @returns MooringDetectionResponse OK
   * @throws ApiError
   */
  public static disableMooringDetection(requestBody: Array<string>): CancelablePromise<Array<MooringDetectionResponse>> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/berths/mooring-detection',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Enable mooring detection
   * Enable mooring detection for a berth. Which means sending a request to the AIS berth service to enable the monitoring of the polygon.
   * @param berthId The berth to deregister mooring detection for.
   * @returns MooringDetectionResponse OK
   * @throws ApiError
   */
  public static enableMooringDetectionForBerth(berthId: string): CancelablePromise<Array<MooringDetectionResponse>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/berths/mooring-detection/{berthId}',
      path: {
        berthId: berthId,
      },
    });
  }

  /**
   * Disable mooring detection
   * Disable the mooring detection for the specified berth. When the berth is not registered before for mooring detection,
   * there will be no call to the AIS berth service to disable this berth!
   *
   * @param berthId The berth to deregister mooring detection for.
   * @returns MooringDetectionResponse OK
   * @throws ApiError
   */
  public static disableMooringDetectionForBerth(berthId: string): CancelablePromise<MooringDetectionResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/berths/mooring-detection/{berthId}',
      path: {
        berthId: berthId,
      },
    });
  }

  /**
   * Retrieve all berths
   * Retrieve the full list of berths in the sysyem, this can become a very large list!!!
   * @param pageNumber The page number to use. 1-based, defaults to 1.
   * @param pageSize The page size to use. 10, 25, 50, 100,....
   * @returns BerthsResponse OK
   * @throws ApiError
   */
  public static listBerths(pageNumber?: number, pageSize?: number): CancelablePromise<BerthsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/berths',
      query: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    });
  }

  /**
   * Register a berth
   * Register a berth in the system, no assignment to an organisation, just the basic berth information (location, polygon, name etc.)
   * @param requestBody Berth to be registered in the system
   * @returns BerthResponse Berth registered
   * @throws ApiError
   */
  public static registerBerth(requestBody: BerthRequest): CancelablePromise<BerthResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/berths',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get berth safety warnings
   * Retrieve the current berth safety warnings for the next 24 hours.
   * @param pageNumber The page number to use. 1-based, defaults to 1.
   * @param pageSize The page size to use. 10, 25, 50, 100,....
   * @returns BerthDmaCaseSafetyResponse OK
   * @throws ApiError
   */
  public static getBerthSafetyWarnings(pageNumber?: number, pageSize?: number): CancelablePromise<BerthDmaCaseSafetyResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/berths/safety-warnings',
      query: {
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    });
  }
}
